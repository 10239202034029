<!--suppress ALL -->
<template>
  <div>
    <!-- Basic Details -->
    <div class="create-candidate-container">
      <div class="create-candidate-header-text">Edit Candidate Details</div>
      <a-form :ref="formRef" scrollToFirstError>
        <div class="create-candidate-basic-details">
          <div class="basic-candidate-info-form">
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">First Name</span
                  ><span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    v-model:value="modelRef.firstName"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">Last Name</span
                  ><span class="required-input">*</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.lastName"
                    placeholder="Enter"
                  ></a-input>
                </a-form-item>
              <a-checkbox v-model:checked="status_temp"><b>Set as active</b></a-checkbox>
              </div>
            </div>
            <br/>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Resume Type</span
                  ><span class="required-input">*</span>
                  <a-select
                    placeholder="Select"
                    class="select-input"
                    v-model:value="modelRef.resumeType"
                    :options="resume_type_options"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">Resume ID</span
                  ><span class="required-input">*</span>
                  <a-input
                    v-model:value="modelRef.resumeId"
                    class="form-input"
                    placeholder="Autogenerates"
                    disabled
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Mobile Number</span>
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    required
                    v-model:value="modelRef.mobile"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">Email ID</span>
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    type="email"
                    v-model:value="modelRef.email"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">LinkedIn Profile</span>
                  <a-input class="form-input" placeholder="Enter"></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">Gender</span>
                  <a-select
                    class="select-input"
                    :options="gender_options"
                    v-model:value="modelRef.gender"
                    placeholder="Select"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Lead Cic</span>
                  <a-select
                    class="select-input"
                    show-search
                    mode="multiple"
                    showArrow
                    placeholder="CIC"
                    :options="users_options"
                    v-model:value="modelRef.cics"
                    @search="getCicData"
                    :default-active-first-option="false"
                    :filter-option="false"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">Current Ctc</span>
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.currentSalary"
                    placeholder="Enter"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Total Work Exp.</span
                  >
                  <a-input
                    class="form-input"
                    placeholder="Enter"
                    v-model:value="modelRef.totalWorkExp"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">City</span
                  >
                  <a-input
                    class="form-input"
                    v-model:value="modelRef.city"
                    placeholder="Enter"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Ratings</span>
                  <br/>
                  <a-rate
                    v-model:value="modelRef.rating"
                  ></a-rate>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
              </div>
            </div>
          </div>
        </div>
        <a-button class="create-candidate-btn" @click="onSubmit"
          >Edit Candidate</a-button
        >
      </a-form>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRaw,
  computed,
  onMounted,
  ref,
} from "vue";
import { Form } from "ant-design-vue";
import { message } from "ant-design-vue";
import { QuillEditor, Delta } from "@vueup/vue-quill";
import Services from "../../../services/apis";
import { useRouter, useRoute } from "vue-router";

let users_options = ref([]);
const getCicData = (value) => {
  let payload = {
    q: value,
    pageNo: 0,
    mode: "AUTOCOMPLETE",
  };

  Services.userListing(payload)
    .then((response) => {
      const { data } = response;
      users_options.value = data.data.map(
        ({ id: value, name: label, ...rest }) => ({
          value,
          label,
          ...rest,
        })
      );
    })
    .catch((e) => {
      console.log("error :>> ", e);
    });
};

export default {
  setup() {
    
    let gender_options = ref([]);
    let candidateData = reactive([]);
    let status_temp = ref(false);
    let modelRef = reactive({
      resumeId: "",
      firstName: "",
      lastName: "",
      mobile: "",
      resumeType: "",
      gender: "",
      cics: [],
      currentSalary:"",
      totalWorkExp:"",
      city:"",
      rating:0,
      status:"",
    });

    const getCandidateProfile = (id) => {
      Services.candidateProfile(id)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(candidateData, response.data);
            modelRef.firstName = candidateData.firstName;
            modelRef.lastName = candidateData.lastName;
            modelRef.resumeId = candidateData.resume.resumeId;
            modelRef.resumeType = candidateData.resume.status;
            modelRef.mobile = candidateData.contacts && candidateData.contacts[0] ? candidateData.contacts[0].phone : '';
            modelRef.email = candidateData.email;
            modelRef.gender = candidateData.gender;
            modelRef.currentSalary = candidateData.ctc;
            modelRef.totalWorkExp = candidateData.totalWorkExp;
            modelRef.rating = candidateData.rating;
            status_temp.value = candidateData.status == "Active" ? true : false;
            fetchUsers();
            let cics = candidateData.relationshipManager.map((cic) => cic.id);
            modelRef.cics = cics;
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    let resume_type_options = ref([]);
    const router = useRouter();
    const {
      params: { id },
    } = useRoute();
    
    const fetchGenderData = (value) => {
      let payload = { types: ["GENDER"] };
      let q = "";
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;

          gender_options.value = data[0].data.map(
            ({ name: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    
    const formRef = ref();
    
    
    onMounted(() => {
      getCicData();
      getCandidateProfile(id), fetchGenderData(), fetchUsers();
      Services.resumeListing({ types: ["RESUME"] })
        .then((response) => {
          const { data } = response;
          resume_type_options.value = data[0].data.map(
            ({ name: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    });

    const fetchUsers = () => {
      let user_listing_payload = {
        q: "",
        pageNo: 0,
        mode: "AUTOCOMPLETE",
        type: "MEMBER",
      };
      Services.userListing(user_listing_payload)
        .then((response) => {
          const { data } = response;
          users_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const candidateForm = Form.useForm;
    
    const { resetFields, validate, validateInfos } = candidateForm(modelRef);

    const onSubmit = () => {
      validate()
        .then((result) => {
          status_temp.value == true ? modelRef.status = 'Active' : modelRef.status = 'Inactive'
          modelRef.totalWorkExp = parseInt(modelRef.totalWorkExp)
          Services.editCandidate({ id: id, data: modelRef }).then(
            (response) => {
              const { data } = response;
              router.push(`/candidates/${id}/detail`);
            }
          );
        })
        .catch((err) => {
          router.push("/candidates");
          console.log("error", err);
        });
    };

    return {
      getCandidateProfile,
      candidateData,
      status_temp,
      onSubmit,
      formRef,
      users_options,
      resume_type_options,
      gender_options,
      modelRef,
      getCicData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/addCandidate.scss";
</style>
